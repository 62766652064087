<template>
  <div class="about">
    <div class="title">
      <img src="./../assets/about/guanyuwomen.png">
    </div>
    <div class="cont">
      <h1>野火科技有限公司成立于2017年</h1>
      <p>总部设在菲律宾，公司注册金额达到数千万比索。野火科技有限公司是菲律宾率先提出网站安全云监测及云防御的高新企业之一，始终致力于为客户提供基于云技术支撑的下一代Web安全解决方案，客户及合作伙伴来自中国、美国、日本 、韩国、以色列、柬埔寨，印度尼西亚等。野火科技有限公司是众多行业企业的重要技术支撑，为企业的网络安全发展做出了重要贡献。</p>
      <p>随着互联网在全世界范围内的高速发展，特别是移动互联网时代的到来，云计算成为一种随时、随地的公共服务，而这些服务的网络质量、安全状况成为保障云计算持续运行的决定性因素。</p>
      <p>野火科技有限公司云安全云防御平台是野火科技有限公司推出的为解决互联网时代的企业Web系统访问速度慢、安全状态严峻这一问题的。目前野火科技有限公司云安全部署了数十个大型云计算中心，储备了数百G的防御带宽，，在保证Web系统快速访问的前提下，知道创宇云安全一站式的安全加速解决方案，以“零部署”、“零维护”、“云防御”的模式，为客户阻止包括XSS、SQL注入、木马、0day攻击、DDoS僵尸网络、DNS攻击等一系列针对Web系统的安全威胁。</p>
      <p>过去、现在与未来，野火将不断提升服务质量，为客户提供一流的服务。</p>
    </div>
  </div>
</template>

<style scoped type="scss">
.about .title{
  margin: auto;
  text-align: center;
  margin: 100px 0 60px;
}
.about .title img{
  width: 282px;
}
.cont{
  margin: auto;
  background-size: 100% 100%;
  background-image: url("../assets/about/wenzikuang.png");
  width: 1260px;
  height: 304px;
  padding: 40px;
  margin-bottom: 120px;
}
.cont p{
  margin: 8px 0;
}
</style>